
import Vue from 'vue'

export default {
    name: 'Head',
    components: {},
    props: {
        title: [Number, String, Boolean],
        option: {
            type: Object,
            default: () => {
                return {
                    hasHead: true,
                    isHidden: false,
                    lastScrollTop: 0
                }
            }
        },
        fnApi: Object
    },
    data() {
        return {
            visible: false,
            hasLogin: '',
            deviceId: '',
            country: {},
            newOption: {},
            advertise: {           // 宣传栏
                visible: false,
                list: [],
                options: {}
            },
            notify: {              // 广告位
                visible: false,
                bgStyle: {},
                list: [],
            },
            bag: {                 // 购物车
                quantity: '',
            },
            quick: {               // 快捷导航
                visible: false,
            },
            user: {                // 用户信息
                id: ''
            },
            defaultField: {
                value: '',
                clearable: true,
                placeholder: this.$translate('Please enter'),
                'left-icon': 'https://cdn2.selleroa.com/yfn-upload/review/1689576966963.png'
            }
        }
    },
    watch: {
        option: {
            handler(obj, o) {
                const n = obj;
                this.newOption = n || {}
                this.newOption.hasHead = n.hasHead ?? true
                this.newOption.hasQuick = n.hasQuick ?? n.hasNav
                this.newOption.hasCollect = n.hasCollect
                this.newOption.hasMine = n.hasMine ?? n.hasNav
                this.newOption.hasSearch = n.hasSearch ?? n.hasNav
                this.newOption.hasBag = n.hasBag ?? n.hasNav
                this.newOption.hasLogo = this.title ? false : n.hasLogo ?? n.hasNav
                this.newOption.hasTitleJump = n.hasTitleJump ?? true
                this.newOption.backFill = n.backFill ?? ''
                this.newOption.style = n.style ?? ''
                n.field?.value ?? (this.defaultField.value = n.field?.value)
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
        this.option.hasAdvertise && this.getAdvertiseAjax()
        this.option.hasNotify && this.getBillboardAjax()
        this.option.hasBag && this.deviceId && this.getUserBagQuantityAjax()
        this.newOption.hasMine && this.hasLogin && this.user.id && this.getUserInfoAjax()
        this.country = this.$storage.get('country') || {}
        // window.addEventListener('scroll', this.handleScroll);
    },
    // beforeDestroy() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // },
    methods: {
        // handleScroll() {
        //     let st = window.pageYOffset || document.documentElement.scrollTop;
        //     console.log(st)
        //     if (st > this.lastScrollTop) {
        //         // 向下滚动，隐藏 div
        //         this.isHidden = true;
        //     } else {
        //         // 向上滚动，显示 div
        //         this.isHidden = false;
        //     }
        //     this.lastScrollTop = st <= 0 ? 0 : st;
        // },
        // 获取宣传栏
        getAdvertiseAjax() {
            this.$api.common.getAd({
                places: 12
            }).then(response => {
                const res = response.result || [];
                this.advertise.list = res
                this.advertise.visible = !!this.advertise.list.length
                this.advertise.options = {
                    loop: true,
                    direction: 'vertical',
                    slidesPerView : 'auto',
                }
                if(this.advertise.list.length > 1) {
                    this.advertise.options.autoplay = {
                        delay: 3000
                    }
                }
            })
        },
        // 获取广告位
        getBillboardAjax() {
            this.$api.mixins.getBillboard().then(response => {
                const res = response.result || {};
                this.notify = {...this.notify, ...res}
                this.notify.list = res.contentList || []
                this.notify.visible = this.notify.list.length
                if(res.backgroundStyle) {
                    this.notify.bgStyle = res.backgroundStyle == 1 ? {background: res.backgroundColor} : {background: `url(${res.picUrl}) no-repeat center / cover`}
                }
            })
        },
        // 获取购物车
        getUserBagQuantityAjax() {
            this.$api.bag.getUserBagQuantity().then(response => {
                let res = response.result || {};
                this.bag.quantity = res.count || ''
            })
        },
        // 获取用户信息
        getUserInfoAjax() {
            if(!this.user.id){
                return
            }
            this.$api.user.getUseInfo({
                id: this.user.id
            }).then(response => {
                const res = response.result || {};
                this.user = res || {}
                this.user.oneStr = this.user.nickName ? this.user.nickName.slice(0, 1) : 'Y'
                this.$storage.set('user/info', this.user)
                this.$storage.set('user/email', this.user.email)
            })
        },
        // 宣传栏
        handleAdvertise(obj) {
            obj.jumpUrl && (window.location.href = obj.jumpUrl)
        },
        // 广告位
        handleNotify(obj) {
            obj.jumpUrl && (window.location.href = obj.jumpUrl)
        },
        // nav
        handleNav(type) {
            const route = this.$route;
            const hasLogin = this.$login();
            // type -> 0 首页 1 返回 2 快捷导航 3 收藏 4 搜索 5 购物车 6 分享 7 个人中心
            let url = ['Home', '', '', 'Wish', 'PreSearch', 'Bag', ''][type]
            let query = {};
            if([4].includes(type) && route.name.includes('Product')) {
                query.from = 'product'
            }
            if(type == 1) {
                if(this.option.hasBackEmit) {
                    this.$emit('emitBack')
                } else {
                    if(window.history.length <= 1) {
                        this.$router.push({
                            name: 'Home'
                        })
                    } else {
                        this.$router.back()
                    }
                }
                return
            }
            if(type == 2) {
                this.setQuick()
                return
            }
            if(type == 6) {
                this.$emit('emitShare')
                return
            }
            if(type == 7) {
                url = 'Mine'
                query = {
                    from: 'head'
                }
            }
            if(type == 3 && !hasLogin) {
                url = 'Login'
            }
            if(!type && !this.newOption.hasTitleJump) {
                return
            }
            this.$router.push({
                name: url,
                query
            })
        },
        // 快捷导航
        setQuick() {
            this.quick.visible = true
        },
        // 初始化
        initialSet() {
            this.hasLogin = this.$login()
            this.deviceId = this.$storage.get('deviceId')
            this.user = {}
            this.user.id = this.$storage.get('userId');
            Vue.prototype.$updateHeadBagQuantity = this.getUserBagQuantityAjax
            if(this.hasLogin && this.user.id) {
                this.user = {...this.$storage.get('user/info'), ...this.user}
            }
        }
    }
}
